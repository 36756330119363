import {
	Button,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Link,
	LinkButton,
	Picture,
	TroonAccess,
} from '@troon/ui';
import { createSignal, createEffect, onMount, Show } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { Icon } from '@troon/icons';
import { twJoin, twMerge } from '@troon/tailwind-preset/merge';
import { AccessProducts } from '../access-products';
import { getConfigValue } from '../../modules/config';
import type { ParentProps } from 'solid-js';

type UpsellProps = {
	class?: string;
	location: string;
	layout?: 'compact' | 'default';
	withPhoto?: boolean;
};

export function UpsellAccess(props: UpsellProps) {
	const [open, setOpen] = createSignal(false);
	const trackEvent = useTrackEvent();

	createEffect(() => {
		if (open()) {
			trackEvent('upsellClicked', { productType: 'troonAccess', location: props.location });
		}
	});

	return (
		<UpsellAccessSection
			layout={props.layout}
			location={props.location}
			withPhoto={props.withPhoto}
			class={props.class}
		>
			<Heading as="h2">Join Troon Access. The ultimate way to experience Troon.</Heading>
			<p>Save up to 50% on tee times at 150+ courses with the all-new Troon Access program.</p>
			<div class="flex flex-wrap justify-start gap-4">
				<Dialog key="access-products" open={open()} onOpenChange={setOpen}>
					<DialogTrigger appearance="primary" class="shrink grow-0">
						Join today
					</DialogTrigger>
					<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
						<div class="flex flex-col items-center gap-4">
							<AccessProducts />
							<LinkButton href="/access" class="size-fit">
								Learn more about Troon Access <Icon name="arrow-right-md" />
							</LinkButton>
						</div>
					</DialogContent>
				</Dialog>
				<Button class="w-fit shrink grow-0" appearance="secondary-current" as={Link} href="/access">
					Learn more<span class="sr-only"> about Troon Access</span>
				</Button>
			</div>
		</UpsellAccessSection>
	);
}

export function UpsellAccessSection(props: ParentProps<UpsellProps>) {
	const trackEvent = useTrackEvent();
	onMount(() => {
		trackEvent('upsellVisible', { productType: 'troonAccess', location: props.location });
	});
	return (
		<div
			class={twMerge(
				twJoin(
					'group relative grid grid-cols-1 overflow-hidden rounded bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
					props.layout === 'compact' && 'gap-4 p-4 md:gap-6 md:gap-x-12 md:p-8',
					(!props.layout || props.layout === 'default') && 'gap-16 p-8 md:gap-x-32 md:p-16',
					props.withPhoto === false ? '' : 'lg:grid-cols-2',
				),
				props.class,
			)}
		>
			<Icon
				name="logo-square"
				class="absolute -right-12 -top-16 -z-0 size-64 origin-center rotate-12 text-brand opacity-20 transition-transform duration-200 gradient-mask-b-0 group-hover:scale-105 md:size-96"
			/>
			<div
				class={twJoin(
					'z-0 flex h-full flex-col justify-center',
					props.layout === 'compact' && 'gap-4 md:gap-6',
					(!props.layout || props.layout === 'default') && 'gap-8',
				)}
			>
				<TroonAccess class="w-full min-w-36 max-w-64" />
				{props.children}
			</div>
			<Show when={props.withPhoto !== false}>
				<Picture
					src={`${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`}
					alt=""
					sizes={[[640, 480]]}
					class="z-0 hidden size-full overflow-hidden rounded bg-center object-cover lg:block"
					loading="lazy"
				/>
			</Show>
		</div>
	);
}
